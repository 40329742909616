module.exports = [{
      plugin: require('C:/Users/asdf/Desktop/dev/personal-projects/hs-filter/hsfilter/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1920,"linkImagesToOriginal":false},
    },{
      plugin: require('C:/Users/asdf/Desktop/dev/personal-projects/hs-filter/hsfilter/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/asdf/Desktop/dev/personal-projects/hs-filter/hsfilter/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/asdf/Desktop/dev/personal-projects/hs-filter/hsfilter/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-113443095-8","head":false,"anonymize":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"]},
    },{
      plugin: require('C:/Users/asdf/Desktop/dev/personal-projects/hs-filter/hsfilter/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#d23b58","showSpinner":true},
    },{
      plugin: require('C:/Users/asdf/Desktop/dev/personal-projects/hs-filter/hsfilter/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HSFilter - Hearthstone deck builder","short_name":"HSFilter - Hearthstone deck builder","start_url":"/","background_color":"#424242","theme_color":"#2C1320","display":"minimal-ui","icon":"src/images/funnel.png"},
    },{
      plugin: require('C:/Users/asdf/Desktop/dev/personal-projects/hs-filter/hsfilter/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/asdf/Desktop/dev/personal-projects/hs-filter/hsfilter/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
